.lang-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lang-selector input {
  margin: 0;
  padding: 0 3px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.hr {
  background-image: url(https://flagcdn.com/hr.svg);
}

.en {
  background-image: url(https://flagcdn.com/gb.svg);
}

.lang-selector input:active + .drinkcard-cc {
  opacity: 0.9;
}

.lang-selector input:checked + .drinkcard-cc {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.drinkcard-cc {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 40px;
  height: 30px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(0.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(0.7);
  filter: brightness(1.8) grayscale(1) opacity(0.7);
}

.drinkcard-cc:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}
