::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(221, 219, 219);
}

.submissionFilterListWrapper {
  position: relative;
  width: 240px;
  /* padding: 12px; */
  height: auto;
  border: 1px solid;
}
.ais-SearchBox-facet {
  width: 100%;
  cursor: pointer;
}
.ais-InfiniteHits {
  height: 200px;
  display: flex;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: white;
  width: 100%;
  padding: 0.5rem 0px;

  position: absolute;
  /* top: 0px; */
  left: 0;
  z-index: 10;
}
.ais-InfiniteHits-list {
  list-style: none;
  width: 100%;
  margin: 0px;
  display: block;
}
.ais-InfiniteHits-item {
  border: none;
  margin: 0px;
  /* margin-bottom: 8px; */
  width: 100%;
  box-shadow: none;
  cursor: pointer;
  padding: 12px;
}

.ais-InfiniteHits-item:hover {
  background-color: #f7fafc;
}

.calendar-toolbar {
  min-height: 80px;
  padding: 16px;
  margin: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 900px) {
  .calendar-toolbar {
    padding: 5px;
    margin: 5px;
    flex-direction: column;
    justify-content: center;
  }
}

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .buttons-wrapper {
    flex-direction: column;
  }
}

.calendar-wrapper {
  padding: 16px;
  margin: 16px;
  overflow-x: auto;
}

.rbc-calendar {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: calc(100vh - 250px);
  width: 100% !important;
}

@media (max-width: 900px) {
  .rbc-calendar {
    width: 880px !important;
  }
}

.rbc-time-view,
.rbc-time-view-resources {
  display: grid;
}

.rbc-event {
  background-color: #00897b !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  font-size: 14px !important;
}

.flex-between {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.plan-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-input {
  width: 300px;
}

.plan-item {
  border: 1px solid rgb(168, 167, 167);
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-item span {
  width: 30%;
}
